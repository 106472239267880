import PropTypes from 'prop-types';
import React from 'react';
import styled, { css } from 'styled-components';
import { Link } from 'gatsby';
import { colors, fonts } from '@styles/variables';

export const buttonCssRules = css`
  display: inline-block;
  min-width: 138px;
  box-sizing: border-box;
  padding: 11px 15px 14px;
  border: 1px solid;
  border-radius: 2px;
  cursor: pointer;
  font: 16px/24px ${fonts.fontPrimaryBold};
  outline: none;
  text-align: center;
  text-decoration: none;

  transition: background-color 0.15s;
  
  &:disabled {
    background: ${colors.colorGray};
  }

  ${({ color }) => {
    switch (color) {
      case 'white': {
        return css`
          border-color: #000000;
          background: none;
          color: #000000;

          &:hover {
            color: #666666;
          }
        `;
      }
      case 'black':
      default: {
        return css`
          border-width: 0;
          background-color: #000000;
          color: #ffffff;

          &:hover {
            background: #666666;
          }

          &:focus {
            box-shadow: 0 0 0 1px ${colors.colorWhite},
              0 0 0 3px ${colors.colorCeruleanBlue};
          }

          &:active {
            box-shadow: none;
          }
        `;
      }
    }
  }}

  ${({ cssRules }) => cssRules}
`;

const StyledButton = styled.button`
  ${buttonCssRules}
`;

const StyledLink = styled(Link)`
  ${buttonCssRules}
  ${({ cssRules }) => cssRules}
`;

const Button = ({ title, to, ...restProps }) => {
  if (to) {
    return (
      <StyledLink to={to} {...restProps}>
        {title}
      </StyledLink>
    );
  }

  return <StyledButton {...restProps}>{title}</StyledButton>;
};

Button.defaultProps = {
  to: '',
  color: 'black',
};

Button.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string,
  color: PropTypes.oneOf(['black', 'white']),
};

export default Button;
