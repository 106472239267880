import React from 'react';
import styled, { css } from 'styled-components';
import { colors, fonts } from '@styles/variables';
import Button from '@components/Button';
import { screen } from '@styles/mixins/breakpoints';

const StyledContainer = styled.div`
  display: flex;
  width: 100%;
  height: 100%;

  box-sizing: border-box;

  flex-direction: column;
  align-items: center;
  justify-content: center;

  padding: 64px 16px 64px;
`;

const StyledText = styled.div`
  max-width: 600px;
  margin-bottom: ${({ marginBottom }) => marginBottom}px;
  color: ${colors.colorBlack};
  font: ${fonts.fontSecondaryBlack};
  font-weight: ${({ fontWeight }) => fontWeight || 900};
  font-size: ${({ fontSize }) => fontSize}px;
  text-align: center;
`;
const Page404 = () => (
  <StyledContainer>
    <StyledText fontSize={118} marginBottom={12}>
      404
    </StyledText>
    <StyledText fontSize={28} marginBottom={18}>
      Oops! Page not found
    </StyledText>
    <StyledText fontSize={16} fontWeight={100}>
      Sorry, the page you're looking for doesn't exist. If you think something
      is broken, report a problem
    </StyledText>
    <Button
      cssRules={css`
        margin-top: 72px;
        ${screen.sm(css`
          margin-top: 52px;
          margin-bottom: 32px;
        `)}
      `}
      to="/"
      title="Return Home"
      color="black"
    />
  </StyledContainer>
);

export default Page404;
